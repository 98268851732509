import { customFetch } from "./requestServices";

export const getFirstResponseSLA = async (paramString, url) => {
  const response = await customFetch(
    `${url}v2/dashboard/first_response_sla/${paramString}`,
    "GET"
  );

  return response;
};

export const getOpenTickets = async (paramString, url) => {
  const response = await customFetch(
    `${url}v2/dashboard/ticket_sla_summary/${paramString}`,
    "GET"
  );
  return response;
};

export const getClosedTickets = async (paramString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/completed_tickets_sla/${paramString.replace(
      "&",
      "?"
    )}`,
    "GET"
  );

  return response;
};

export const getFiltersData = async (url) => {
  const response = await customFetch(`${url}v2/ticket/filter_config`, "GET");

  return response;
};

export const getStatusBarChartData = async (paramString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/status_duration/${paramString.replace(
      "&",
      "?"
    )}`,
    "GET"
  );
  return response;
};

export const getSquadBarChartData = async (paramString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/squad_duration/${paramString.replace("&", "?")}`,
    "GET"
  );

  return response;
};

export const getClosedBarChartData = async (parameterString, url) => {
  const response = await customFetch(
    `${url}v1/dashboard_metric/tickets_closed_by_squad/${parameterString.replace(
      "&",
      "?"
    )}`,
    "GET"
  );
  return response;
};

export const getDashboardMetricData = async (url) => {
  const response = await customFetch(`${url}`, "GET");
  return response;
};

export const getDashboardMetricExportData = async (parameterString, url) => {
  const response = await fetch(
    `${url}v1/dashboard_metric/export/?${parameterString.slice(1)}`,
    {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Token ${localStorage.getItem("access_token")}`,
      },
    }
  );
  return response;
};
